// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2022_02-module--afterCaption--78e79";
export var colWrapper = "maine_digest_2022_02-module--colWrapper--e7b2a";
export var columnWrapper = "maine_digest_2022_02-module--columnWrapper--e066f";
export var dateline = "maine_digest_2022_02-module--dateline--1d837";
export var heading = "maine_digest_2022_02-module--heading--4e4a2";
export var headingLogo = "maine_digest_2022_02-module--headingLogo--8135b";
export var headingWrapper = "maine_digest_2022_02-module--headingWrapper--1ca38";
export var heroImage = "maine_digest_2022_02-module--heroImage--9ee0b";
export var instance = "maine_digest_2022_02-module--instance--38f2f";
export var latestnewsarticleheadline = "maine_digest_2022_02-module--latestnewsarticleheadline--a64f7";
export var leftCol = "maine_digest_2022_02-module--leftCol--73c24";
export var maineDigest = "maine_digest_2022_02-module--maineDigest--493c0";
export var photoCaption = "maine_digest_2022_02-module--photoCaption--5261d";
export var rightCol = "maine_digest_2022_02-module--rightCol--3f7e6";
export var subheading = "maine_digest_2022_02-module--subheading--e3338";
export var textWrapper = "maine_digest_2022_02-module--textWrapper--e7164";
export var whatsNew = "maine_digest_2022_02-module--whatsNew--dcc3e";